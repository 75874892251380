import request from "../plugins/axios";

export function participateIndex(params) {
    return request({
        url: '/singlePag/participate',
        method: 'GET',
        params
    })
}

// 客户参加活动统计
export function joinTotal(params) {
    return request({
        url: '/articleSpread/joinTotal',
        method: 'GET',
        params
    })
}

// 客户参加活动数据列表
export function joinList(params) {
    return request({
        url: '/articleSpread/joinList',
        method: 'GET',
        params
    })
}

// 成员活动列表
export function joinEmployee(params) {
    return request({
        url: '/articleSpread/employeeIndex',
        method: 'GET',
        params
    })
}

// 智慧传播列表
export function articleSpread(params) {
    return request({
        url: '/articleSpread/index',
        method: 'GET',
        params
    })
}

// 成员活动客户列表
export function joinMemberList(params) {
    return request({
        url: '/articleSpread/joinMemberList',
        method: 'GET',
        params
    })
}

// 成员活动留资列表
export function joinLeaveInfoList(params) {
    return request({
        url: '/articleSpread/joinLeaveInfoList',
        method: 'GET',
        params
    })
}