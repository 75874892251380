<template>
  <div>
    <div class="title"><van-icon name="arrow-left" style="margin-right: 6px" @click="$router.back()" /> {{title}}</div>
    <div class="screen">
      <van-button plain hairline size="small" @click="$refs.employee.$show(selectedEmployee,'max')" icon="https://img01.yzcdn.cn/vant/user-active.png" type="info">
        {{ selectedEmployee[0] ? selectedEmployee[0].name : '按员工查看' }}
      </van-button>
    </div>
    <van-pull-refresh v-model="refreshLoading" @refresh="refreshData">
      <van-list 
        v-model="listLoading" 
        :finished="listFinished" 
        :finished-text="list.length > 0 ? '没有更多了' : ''" 
        @load="getList"
      >
        <div v-for="(item, index) in list" :key="index" class="activity-list">
          <div class="employee" v-if="item.employee">
            <div>
              <van-image
                round
                width="36px"
                height="36px"
                :src="item.employee.avatar"
              />
              {{ item.employee.name }}
            </div>
            <div>{{ item.created_at }}</div>
          </div>
          <div class="activity-list-summary">
            <van-row gutter="12">
              <van-col span="8">分享次数<span>{{ item.shares }}</span></van-col>
              <van-col span="8">分享受众<span>{{ item.unique_view }}</span></van-col>
              <van-col span="8">裂变人数<span>{{ item.fission_unique_view }}</span></van-col>
              <van-col span="8">裂变留资<span>{{ item.fission_info_number }}</span></van-col>
              <van-col span="8">裂变层数<span>{{ item.level }}</span></van-col>
              <van-col span="8"></van-col>
            </van-row>
          </div>
        </div>
      </van-list>
      <van-empty description="暂无数据" v-if="!listLoading && list.length == 0"/>
      <employeeIndex ref="employee" @change="userChange"/>
    </van-pull-refresh>
  </div>
</template>
<script>
import { joinEmployee } from '@/api/participate'
import employeeIndex from "@/views/channelCode/components/employeeIndex"
export default {
  components: {
    employeeIndex
  },
  data () {
    return {
      list: [],
      refreshLoading: false,
      listLoading: false,
      listFinished: false,
      page: 1,
      selectedEmployee: [],

      title: '',
      id: ''
    }
  },
  created () {
    this.title = this.$route.query.title
    this.id = this.$route.query.id
  },
  mounted () {
    // participateIndex().then(res => {
    //   this.res = res.data
    //   this.show = true
    // })
  },
  methods: {
    getList () {
      let param = {
        page: this.page,
        article_spread_id: this.id,
      }
      if (this.selectedEmployee.length) {
        param.employee_id = this.selectedEmployee[0].id
      }
      joinEmployee(param).then(res => {
        this.list = this.list.concat(res.data.lists.data)
        if (this.list.length == res.data.lists.total) {
          this.listFinished = true
        } else {
          this.page++
        }
        this.listLoading = false
         this.refreshLoading = false
      })
    },
    refreshData () {
      this.refreshLoading = true
      this.page = 1
      this.listLoading = true
      this.list = []
      this.getList()
    },
    userChange(data, info) {
      this.selectedEmployee = data
      this.page = 1
      this.listLoading = true
      this.list = []
      this.getList()
      // this.StandbyStaff = data.map(i=>i.name)
    },
  }
}
</script>
<style lang="less" scoped>
.index {
  background: #fff;
}
.screen {
  padding: 12px;
  margin-bottom: 12px;
}
.van-list {
  background-color: #fff;
}
.employee {
  display: flex;
  align-items: center;
  padding: 0 12px 4px;

  >div:first-child {
    flex: 1;
  }

  >div:last-child {
    font-size: 12px;
  }

  .van-image {
    vertical-align: middle;
    margin-right: 4px;
  }
}
.title {
    padding: 12px;
    background: #fff;
    border-bottom: 1px solid #eee;
    font-size: 14px;
}

.activity-list {
  border-bottom: 1px solid #eee;
  margin-bottom: 16px;

  &-title {
    padding: 0 12px;
    // color: #1989fa;
    // font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    
    div {
      margin-top: 5px;
      font-size: 12px;
      color: #999;
      font-weight: 400;
    }
  }
  &-summary {
    text-align:center; 
    padding: 6px 0 10px;
    background: #f2f3f7;
    border-radius: 8px;
    margin: 6px 12px 16px;

    .van-col {
      display: flex;
      flex-flow: column;
      // padding-bottom: 4px;
      padding-top: 4px;
      font-size: 12px;

      span {
        color: #1989fa;
        font-size: 16px;
        // font-weight: 600;
      }
    }
  }
}
</style>>

